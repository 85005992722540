import { Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { SlOptions } from "react-icons/sl";
import { CgTrash } from "react-icons/cg";
import { TbFlag3 } from "react-icons/tb";
import { IoImageOutline } from "react-icons/io5";
import { IoMdSend } from "react-icons/io";

import { AiOutlineCloseCircle } from "react-icons/ai";

import InputEmoji from "react-input-emoji";
import { classNames, useUrlState } from "@/utils";
import ReportModal from "./modals/report";
import ClearChat from "./modals/clear-chat";
import DeleteChat from "./modals/delete";
import { useConversation } from "@/hooks/useConversation";
import { useMessages } from "@/hooks/useMessages";
import _ from "lodash";
import { ConversationUpdatedSubscription, ConversationUpdatedSubscriptionVariables, MarkAllMessagesAsReadMutation, MarkAllMessagesAsReadMutationVariables, Message, MessageAddedSubscription, MessageAddedSubscriptionVariables, MessageStatus, MessageType, SendMessageMutation, SendMessageMutationVariables, UserType } from "@/apollo/messages/generated/types";
import moment from "moment";
import { useMutation, useSubscription } from "@apollo/client";
import { CONVERSATIONS_UPDATED, MARK_ALL_AS_READ, MESSAGE_ADDED, SEND_MESSAGE } from "@/apollo/messages";
import { useAppNavigate, useCurrentUser, useCurrentUserPermissions } from "@/apollo/cache/auth";
import List from "@/components/core/list";
import Avatar from "@/components/core/avatar";
import { PiCheckThin, PiChecksThin } from "react-icons/pi";
import { Show } from "@/components";
import { hasPermission, PERMISSIONS } from "@/permissions";

interface Props {
    show?: (value: boolean) => void;
}

const ChatDetails: React.FC<Props> = ({ show }) => {
    const currentUserPermissions = useCurrentUserPermissions();
    const navigate = useAppNavigate();
    const [selectedChat] = useUrlState("conversation_id");
    const [allMessages, setAllMessages] = useState<Message[]>([]);
    const employer = useCurrentUser();
    const { conversation } = useConversation({ variables: { getConversationId: selectedChat } });
    
    const [clearChatModal, setClearChatModal] = useState(false);
    const [deleteChatModal, setDeleteChatModal] = useState(false);

    const endOfMessagesRef = useRef<HTMLDivElement | null>(null);

    const { messages, fetchMore } = useMessages({ variables: { conversationId: selectedChat, pagination: {} } });

    const { data } = useSubscription<ConversationUpdatedSubscription, ConversationUpdatedSubscriptionVariables>(CONVERSATIONS_UPDATED);

    const [sendMessage, { loading: sendLoading }] = useMutation<SendMessageMutation, SendMessageMutationVariables>(SEND_MESSAGE, { refetchQueries: ["GetConversation", "GetMessages"], context: { apiName: "message" } });

    const [markAllAsRead] = useMutation<MarkAllMessagesAsReadMutation, MarkAllMessagesAsReadMutationVariables>(MARK_ALL_AS_READ, { refetchQueries: ["GetConversations"], context: { apiName: "message" } });

    const [text, setText] = useState("");

    const handleSendMessage = async () => {
        if (!_.isEmpty(text)) {
            await sendMessage({ variables: { senderId: employer?.id || "", message: text, conversationId: selectedChat } })
                .then(({ data }) => {
                    if (data?.sendMessage) {
                        setText("");
                    }
                })
                .catch((err) => {
                    console.log("Something went wrong");
                });
            await markAllAsRead({ variables: { conversationId: selectedChat, senderType:UserType.Employer } });
        }
    };
    function handleOnEnter() {
        if (!_.isEmpty(text)) {
            handleSendMessage();
        }
    }

    useEffect(() => {
        fetchMore({ variables: { conversationId: selectedChat, pagination: {} } });
    }, [data?.conversationUpdated]);

    useEffect(() => {
        if (endOfMessagesRef.current) {
            endOfMessagesRef.current.scrollIntoView({ behavior: "auto" });
        }
    }, [messages, data?.conversationUpdated]);

    return (
        <div className="relative flex flex-col w-full h-full">
            <div className=" sticky top-0 bg-white z-10 flex justify-between items-center border-b border-gray-300 py-2.5 px-4">
                <div className="flex items-center gap-x-3 ">
                    <BiArrowBack onClick={() => navigate({ to: "/messages" })} className="w-5 h-5 text-black cursor-pointer" />
                    <div className="w-10 h-10 rounded-full bg-[#B1B1B1] ">
                        <Avatar src={conversation?.talent?.profilePicture || ""} alt={conversation?.talent?.fullName || ""} size="sm" />
                    </div>
                    <h2 className="font-medium text-base text-black ">{conversation?.talent?.fullName}</h2>
                </div>

                <div className="">
                    <Menu as="div" className="relative">
                        <MenuButton className="-m-1.5 flex items-center p-1.5">
                            <span className="sr-only">Open chat menu</span>
                            <SlOptions className="w-6 h-6 text-[#8A8A8A]" aria-hidden="true" />
                        </MenuButton>
                        <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                            <MenuItems className="absolute right-0 z-30 mt-2.5 w-52 origin-top-right rounded-md bg-white py-4 shadow-xl ring-1 ring-gray-900/5 focus:outline-none">
                                {/* <MenuItem>
                                    {() => (
                                        <div
                                            onClick={() => {
                                                setReportModal(true);
                                            }}
                                            className={classNames("block  py-1 text-sm leading-6 text-gray-900 cursor-pointer")}>
                                            <div className="flex items-center gap-x-2 border-b border-[#F0F0F0] py-2 px-3">
                                                <TbFlag3 className="w-5 h-5 text-[#6B6B6B] " />
                                                <span className="font-medium text-[#6B6B6B] text-sm">Report Conversation</span>
                                            </div>
                                        </div>
                                    )}
                                </MenuItem> */}
                                <MenuItem>
                                    {() => (
                                        <div
                                            onClick={() => {
                                                setClearChatModal(true);
                                            }}
                                            className={classNames("block  py-1 text-sm leading-6 text-gray-900 cursor-pointer")}>
                                            <div className="flex items-center gap-x-2 border-b border-[#F0F0F0] py-2 px-3">
                                                <AiOutlineCloseCircle className="w-5 h-5 text-[#6B6B6B] " />
                                                <span className="font-medium text-[#6B6B6B] text-sm">Clear Chat History</span>
                                            </div>
                                        </div>
                                    )}
                                </MenuItem>
                                <MenuItem>
                                    {({ active }) => (
                                        <div className={classNames(active ? "bg-gray-50" : "", "block  text-xs  text-gray-900")}>
                                            <div
                                                onClick={() => {
                                                    setDeleteChatModal(true);
                                                }}
                                                className="flex text-red-500 cursor-pointer items-center space-x-1 py-1 px-3">
                                                <CgTrash className="w-5 h-5" />
                                                <span className="font-montserrat text-sm">Delete Conversation</span>
                                            </div>
                                        </div>
                                    )}
                                </MenuItem>
                            </MenuItems>
                        </Transition>
                    </Menu>
                </div>
            </div>
            <div className="p-4 pb-0 flex-1 mobile:pb-20 sm:pb-20 md:pb-10 h-full overflow-scroll overflow-y-scroll chatScrollbar">
                <ul className="w-full ">
                    <List
                        data={messages || []}
                        renderItem={(message: Message) => (
                            <li key={message?.id} className={`w-full mb-3 flex flex-col  ${message?.senderType === UserType.Employer ? "items-end" : "items-start"}`}>
                                <div className={`flex justify-between items-end max-w-xs py-2 pl-3 pr-1 mb-0.5 rounded-[20px]  ${message?.senderType === UserType.Employer ? "bg-primary rounded-ee-none" : "bg-[#F5F6F9] rounded-es-none"}`}>
                                    <p className={`w-full overflow-x-hidden flex-1 text-xs ${message?.senderType === UserType.Employer ? "text-white" : "text-[#171C1B]"}`}>{message?.message}</p>
                                    <span className="flex justify-end items-center mt-0.5">
                                        {message?.status === MessageStatus.Read ? (
                                            <PiCheckThin className="h-3 w-3 text-white font-light" />
                                        ) : message?.status === MessageStatus.Delivered ? (
                                            <PiChecksThin className="h-3 w-3 text-white" />
                                        ) : message?.status === MessageStatus.Sent ? (
                                            <PiChecksThin className="h-3 w-3 text-green-200" />
                                        ) : (
                                            <></>
                                        )}
                                    </span>
                                </div>
                                <span className="font-montserrat text-[11px] text-gray-400 select-none">{moment(message?.createdAt).fromNow()}</span>
                            </li>
                        )}
                        renderEmptyComponent={() => (
                            <div className="h-full min-h-96 w-full flex justify-center items-center">
                                <div className="flex items-center justify-center flex-col">
                                    <h1 className="font-semibold">Start Conversation</h1>
                                    <p className="text-gray-500 text-xs">Send an instant message to this candidate now</p>
                                </div>
                            </div>
                        )}
                    />
                    <li>
                        <div ref={endOfMessagesRef} />
                    </li>
                </ul>
            </div>

            <div className="fixed md:sticky bg-white w-full bottom-0 border-t border-gray-300 py-4 px-4">
                <Show if={true}>
                    <div className="w-full flex items-center ring-1 ring-gray-300 rounded-full pl-4 pr-2  max-h-20 overflow-hidden">
                        {/* <button onClick={() => {}} type="button" className="flex items-center">
                        <IoImageOutline className="w-5 h-5 text-gray-600" aria-hidden="true" />
                    </button> */}

                        <div className="flex-1 w-full overflow-x-hidden overflow-y-scroll">
                            <InputEmoji value={text} onChange={setText} cleanOnEnter onEnter={handleOnEnter} placeholder="Type message" placeholderColor={"#475467"} shouldReturn shouldConvertEmojiToImage={false} fontSize={16} fontFamily={"Montserrat"} borderColor={"transparent"} />
                        </div>

                        <button onClick={handleSendMessage} type="button" className="flex items-center justify-center w-8 h-8 rounded-full bg-primary hover:bg-primary-600 text-white">
                            <IoMdSend className="w-5 h-5 text-white " />
                        </button>
                    </div>
                </Show>
            </div>
            {/* <ReportModal open={reportModal} setOpen={setReportModal} /> */}
            <ClearChat open={clearChatModal} setOpen={setClearChatModal} />
            <DeleteChat open={deleteChatModal} setOpen={setDeleteChatModal} />
        </div>
    );
};

export default ChatDetails;
